
import { isMobile, isTablet } from '~/helpers/mobile/DeviceType'
import { convertUCWordsOrCamelCaseToDashCase, smoothScrollToTargetId } from '~/helpers/util'
import baseConstants from '~/store/base/-constants'
import projectConstants from '~/store/project/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import guidedTourConstants from '~/store/guidedTour/-constants'

export default {
  name: 'OrganismMenuBarProject',
  data() {
    return {
      isScrolled: false
    }
  },
  computed: {
    i18nActive() {
      return !!this.$store.state.base.meta.generalConfig.i18nActive
    },
    currentProject() {
      const currentProject = this.$store.state.project.projects.find((item) => {
        return item.slug === this.$store.state.project.activeProject
      })
      if (!currentProject) {
        return ''
      }
      return currentProject.slug
    },
    projectOptions() {
      const projects = [...this.$store.state.project.projects]
      return projects.map((project) => {
        return { label: project.name, value: project.slug }
      })
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    showBackButton() {
      return !!this.$route.params.space
    },
    showProjectSelect() {
      const { name } = this.$route
      return name !== 'SpecificSpace'
    },
    sections() {
      const { page } = this.$route.meta
      return page ? page.sections : []
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    simplifiedView() {
      return this.guidedTourModeEnabled
    }
  },
  mounted() {
    document.addEventListener('scroll', this.initScrollListener)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.initScrollListener)
  },
  methods: {
    handleBackFromSimplifiedView() {
      this.$router.push({
        path: `/project/${this.$route.params.project}`
      })
      this.$store.dispatch(guidedTourConstants.withNamespace('disableGuidedTourMode'))
    },
    initScrollListener() {
      this.isScrolled = window.scrollY > 40
    },
    handleProjectChange(option) {
      if (this.currentProject === option.value) return
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.CHANGE_ACTIVE_PROJECT),
        option.value
      )
      this.$router.push({
        name: 'SpecificProject',
        params: { projectId: option.value }
      })
    },
    handleScrollToTop() {
      this.scrollTo3DSection()
      if (isMobile() || isTablet()) {
        this.$store.dispatch(
          baseConstants.withNamespace(baseConstants.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
        )
        this.$store.dispatch(
          baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE),
          false
        )
        this.$store.dispatch(
          requestOfferConstants.withNamespace(
            requestOfferConstants.action.CHANGE_CART_SPACES_STATE
          ),
          false
        )
      }
    },
    scrollTo3DSection() {
      const sections = this.sections
      const buildingSection = this.sections.find((s) => s.type === 'Building')
      if (buildingSection) {
        smoothScrollToTargetId(`#${buildingSection.hashAnchor}`)
      }
    }
  }
}
